<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 11:00:25
 * @ Description: Component displaying the individual profile education tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Add Education Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                v-if="!showAddEditEducationBoolean"
                @click="showAddEditEducation(true)"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Education</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Education Button -->

            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Education -->
                <v-col
                    v-if="educations === null || educations.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No education added
                </v-col>
                <!-- No Education -->

                <!-- Education -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in educations"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="152px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="6"
                                class="pb-0"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiSchoolOutline }}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right pb-0"
                            >
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(menuItem, menuIndex) in menuItems"
                                            :key="menuIndex"
                                            @click="menuItemAction(menuItem, item)"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ menuItem }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center pt-0 text-truncate"
                                cols="12"
                            >
                                {{ item.course_name }}
                                <br />
                                <span class="os-14-r">({{ item.qualification.name }})</span>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                class="os-12-sb flex-shrink-1 flex-grow-0"
                                style="color: #2E567A"
                            >
                                {{ item.completion_year }}
                            </v-col>
                            <v-col
                                class="os-10-r text-right flex-grow-1 flex-shrink-0 text-truncate"
                                style="color: #8F9BB3;"
                                align-self="center"
                            >
                                {{ item.institution_name }}
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Education -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Education
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                                v-model="search"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- No Education -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="educationTableHeaders"
                        :items="educations"
                        item-key="id"
                        hide-default-footer
                        no-data-text="No education added"
                        :no-results-text="noResultsText"
                        :search="search"
                    >
                        <template v-slot:[`item.qualification`]="{ item }">
                            {{ item.qualification.name }}
                        </template>
                        <template v-slot:[`item.institution_name`]="{ item }">
                            {{ item.institution_name }}
                        </template>
                        <template v-slot:[`item.reference_number`]="{ item }">
                            <span v-if="item.reference_number">{{ item.reference_number }}</span>
                            <span
                                class="os-12-r"
                                style="color: rgba(0, 0, 0, 0.38);"
                                v-else
                            >
                                No reference number
                            </span>
                        </template>
                        <template v-slot:[`item.course_name`]="{ item }">
                            {{ item.course_name }}
                        </template>
                        <template v-slot:[`item.language`]="{ item }">
                            {{ item.language.name }}
                        </template>
                        <template v-slot:[`item.completion_year`]="{ item }">
                            {{ item.completion_year }}
                        </template>
                        <template v-slot:[`item.action`]>
                            <v-btn
                                icon
                                @click="menuItemAction ('Edit', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn> -->
                            <v-btn
                                icon
                                @click="menuItemAction ('Delete', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <!-- No Education -->
            </v-row>
            <!-- List View -->

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditEducationBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Education
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- Education Form -->
                        <v-col
                            cols="7"
                        >
                            <v-form
                                v-model="validEducationForm"
                                ref="educationForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="6"
                                        class="pb-0 os-15-sb"
                                    >
                                        Type of Qualification<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :items="qualifications"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            :rules="selectRules"
                                            v-model="educationToUpsert.qualification"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="pb-0 os-15-sb"
                                    >
                                        Institution<span style="color: red;">*</span>
                                        <br />
                                        <gmap-autocomplete @place_changed="setPlace">
                                            <template v-slot:input="slotProps">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    placeholder="Type to select a Google Place"
                                                    ref="input"
                                                    v-on:listeners="slotProps.listeners"
                                                    v-on:attrs="slotProps.attrs"
                                                    :rules="longNameRules"
                                                    counter="100"
                                                    v-model="educationToUpsert.institution_name"
                                                />
                                            </template>
                                        </gmap-autocomplete>
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Reference Number
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            counter="50"
                                            v-model="educationToUpsert.reference_number"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Course Name<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :rules="nameRules"
                                            counter="50"
                                            v-model="educationToUpsert.course_name"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Language Skills<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :items="languageSkills"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            :rules="selectRules"
                                            v-model="educationToUpsert.language"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="py-0 os-15-sb"
                                    >
                                        Year of Completion<span style="color: red;">*</span>
                                        <br />
                                        <v-autocomplete
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :items="completionYears"
                                            :rules="yearRules"
                                            v-model="educationToUpsert.completion_year"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- Education Form -->

                        <!-- Add/Edit/Cancel Buttons -->
                        <v-col
                            align-self="end"
                            class="pb-7"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-13-sb"
                                color="#FFA858"
                                @click="upsertEducation"
                                :disabled="!validEducationForm"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Education
                                <v-icon
                                    class="ml-1"
                                    v-if="action === 'add'"
                                >
                                    {{ mdiPlusCircleOutline }}
                                </v-icon>
                                <v-icon
                                    class="ml-1"
                                    v-else-if="action === 'edit'"
                                >
                                    {{ mdiCircleEditOutline }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="no-uppercase rounded-lg os-13-sb"
                                color="#8F9BB3"
                                @click="showAddEditEducation(false)"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <!-- Add/Edit/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditEducationBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validEducationForm"
                        ref="educationForm"
                    >
                        <v-row>
                            <!-- Heading -->
                            <v-col
                                cols="12"
                                class="os-22-sb"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Education
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <!-- Heading -->

                            <!-- Education Form -->
                            <v-col
                                cols="12"
                                sm="6"
                                class="pb-0 os-15-sb"
                            >
                                Type of Qualification<span style="color: red;">*</span>
                                <br />
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="qualifications"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :rules="selectRules"
                                    v-model="educationToUpsert.qualification"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="os-15-sb"
                                :class="$vuetify.breakpoint.sm ? 'pb-0' : 'py-0'"
                            >
                                Institution<span style="color: red;">*</span>
                                <br />
                                <gmap-autocomplete @place_changed="setPlace">
                                    <template v-slot:input="slotProps">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            placeholder="Type to select a Google Place"
                                            ref="input"
                                            v-on:listeners="slotProps.listeners"
                                            v-on:attrs="slotProps.attrs"
                                            :rules="longNameRules"
                                            counter="100"
                                            v-model="educationToUpsert.institution_name"
                                        />
                                    </template>
                                </gmap-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Reference Number
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    counter="50"
                                    v-model="educationToUpsert.reference_number"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Course Name<span style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :rules="nameRules"
                                    counter="50"
                                    v-model="educationToUpsert.course_name"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Language Skills<span style="color: red;">*</span>
                                <br />
                                <v-select
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="languageSkills"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :rules="selectRules"
                                    v-model="educationToUpsert.language"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                class="py-0 os-15-sb"
                            >
                                Year of Completion<span style="color: red;">*</span>
                                <br />
                                <v-autocomplete
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    :items="completionYears"
                                    :rules="yearRules"
                                    v-model="educationToUpsert.completion_year"
                                />
                            </v-col>
                            <!-- Education Form -->

                            <!-- Add/Edit/Cancel Buttons -->
                            <v-col
                                cols="12"
                                class="text-right"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="upsertEducation"
                                    :disabled="!validEducationForm"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                    <v-icon
                                        class="ml-1"
                                        v-if="action === 'add'"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    <v-icon
                                        class="ml-1"
                                        v-else-if="action === 'edit'"
                                    >
                                        {{ mdiCircleEditOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    @click="showAddEditEducation(false)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                            <!-- Add/Edit/Cancel Buttons -->
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Education'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import { LookupEducationController, IndividualProfileEducationController } from '@/logic/controller/index.controller.js'
    import { mdiPlusCircleOutline, mdiCircleEditOutline, mdiDotsHorizontal, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiMagnify, mdiSchoolOutline } from '@mdi/js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'

    export default {
        name: 'ProfileIndividualEducationTabComponent.vue',

        components: {
            GeneralConfirmDeleteOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            yearRules () {
                return [
                    v => !!v || 'Required Field'
                ]
            },

            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            longNameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 100 || 'Max 100 characters'
                ]
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiCircleEditOutline,
                mdiDotsHorizontal,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                mdiSchoolOutline,
                showAddEditEducationBoolean: false,
                educationTableHeaders: [
                    {
                        text: 'Qualification',
                        value: 'qualification.name'
                    },
                    {
                        text: 'Course',
                        value: 'course_name'
                    },
                    {
                        text: 'Institution',
                        value: 'institution_name'
                    },
                    {
                        text: 'Reference',
                        value: 'reference_number'
                    },
                    {
                        text: 'Language Skills',
                        value: 'language.name'
                    },
                    {
                        text: 'Year of Completion',
                        value: 'completion_year'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                educations: [],
                menuItems: [
                    'Edit',
                    // 'Print',
                    'Delete'
                ],
                qualifications: [],
                languageSkills: [],
                completionYears: [],
                lookupEducationController: null,
                individualProfileEducationController: null,
                action: 'add',
                educationToUpsert: {
                    user_id: this.$store.state.user.userData.id,
                    institution_name: '',
                    reference_number: null,
                    course_name: '',
                    completion_year: '',
                    qualification: {},
                    language: {}
                },
                validEducationForm: false,
                search: '',
                showDeleteConfirmationOverlayBoolean: false,
                educationToDelete: null
            }
        },

        beforeMount () {
            this.lookupEducationController = new LookupEducationController()
            this.individualProfileEducationController = new IndividualProfileEducationController()
        },

        async mounted () {
            await this.getQualifications()
            await this.getLanguageSkills()
            await this.getCompletionYears()
            await this.getProfileEducation()
        },

        methods: {
            async getProfileEducation () {
                try {
                    this.educations = await this.individualProfileEducationController.retrieve(this.$store.state.user.userData.id)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getQualifications () {
                try {
                    this.qualifications = await this.lookupEducationController.getQualifications()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getLanguageSkills () {
                try {
                    this.languageSkills = await this.lookupEducationController.getLanguageSkills()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCompletionYears () {
                try {
                    this.completionYears = await this.lookupEducationController.getCompletionYears()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertEducation () {
                try {
                    this.educations = await this.individualProfileEducationController.upsert(this.educationToUpsert)

                    if (this.action === 'edit') {
                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    }

                    this.showAddEditEducationBoolean = false
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmDeleteEducation (education) {
                this.educationToDelete = education
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteEducation (education) {
                try {
                    this.educations = await this.individualProfileEducationController.delete(education)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.educationToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showAddEditEducation (value) {
                this.showAddEditEducationBoolean = value
                this.action = 'add'

                this.educationToUpsert = {
                    user_id: this.$store.state.user.userData.id,
                    institution_name: '',
                    reference_number: null,
                    course_name: '',
                    completion_year: '',
                    qualification: {},
                    language: {}
                }
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.educationToUpsert, item)
                    this.action = 'edit'
                    this.showAddEditEducationBoolean = true
                } else if (action === 'Delete') this.confirmDeleteEducation(item)
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteEducation(this.educationToDelete)
            },

            setPlace (place) {
                this.educationToUpsert.institution_name = place.name
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>
